import { getSafe } from './getSafe';

export const getCocktailCharacteristics = data => {
    const characteristics =
        data.nodeCocktail.relationships.field_locale_cocktail_categories &&
        data.nodeCocktail.relationships.field_locale_cocktail_categories.map(item => {
            return {
                name: getSafe(() => item.name),
                parent: getSafe(() => item.relationships.parent[0].name),
            };
        });
    return characteristics;
};

export const getVendors = data => {
    const vendors =
        data.nodeCocktail.relationships.field_product_related &&
        data.nodeCocktail.relationships.field_product_related.relationships.field_vendors
            ? data.nodeCocktail.relationships.field_product_related.relationships.field_vendors.map(vendor => {
                  return {
                      alt: getSafe(
                          () => vendor.relationships.field_vendor.relationships.field_logo.field_media_image.alt
                      ),
                      img: getSafe(
                          () =>
                              vendor.relationships.field_vendor.relationships.field_logo.relationships.field_media_image
                                  .localFile,
                          null
                      ),
                      url: getSafe(() => vendor.field_product_link, '#'),
                  };
              })
            : [];

    return vendors;
};

export const getTabs = (data, characteristics, vendors) => {
    let tabs = [];

    let cta = {
        title: getSafe(() => data.nodeCocktail.relationships.field_learn_cta.field_title, 'LEARN MORE'),
        link: getSafe(() => data.nodeCocktail.relationships.field_learn_cta.relationships.field_cta_url.path.alias, ''),
        isLight: getSafe(() => data.nodeCocktail.relationships.field_learn_cta.field_islight, false),
    };

    const recipe = {
        tabTitle: getSafe(() => data.nodeCocktail.field_recipe_tab_title, 'RECIPE'),
        ingredientsTitle: getSafe(() => data.nodeCocktail.field_ingredients_title, ''),
        ingredients: getSafe(() => data.nodeCocktail.field_cocktail_ingredients, []),
        directionsTitle: getSafe(() => data.nodeCocktail.field_direction_title, ''),
        directions: getSafe(() => data.nodeCocktail.field_cocktail_recipe.value, ''),
        id: 'recipe',
    };
    if (recipe.tabTitle && (recipe.ingredients || recipe.directions)) {
        tabs.push(recipe);
    }

    const profile = {
        tabTitle: getSafe(() => data.nodeCocktail.field_profile_tab_title, 'PROFILE'),
        profileTitle: getSafe(() => data.nodeCocktail.field_description_title, ''),
        profileDescription: getSafe(() => data.nodeCocktail.field_description.value, ''),
        characteristicsTitle: getSafe(() => data.nodeCocktail.field_characteristics_title, ''),
        characteristics,
        id: 'profile',
    };
    if (profile.tabTitle && (profile.profileDescription || profile.characteristics)) {
        tabs.push(profile);
    }
    const collection = {
        tabTitle: getSafe(() => data.nodeCocktail.field_collection_tab_title, 'COLLECTION'),
        productTitle: getSafe(() => data.nodeCocktail.relationships.field_product_related.field_product_name, ''),
        productSummary: getSafe(
            () => data.nodeCocktail.relationships.field_product_related.field_product_summary.value,
            ''
        ),
        image: getSafe(
            () =>
                data.nodeCocktail.relationships.field_product_related.relationships.field_product_images[0]
                    .relationships.field_media_image.localFile,
            null
        ),
        titleContent: getSafe(() => data.nodeCocktail.relationships.field_product_related.field_shop_title_content, ''),
        description: getSafe(
            () => data.nodeCocktail.relationships.field_product_related.field_shop_tab_description.value,
            ''
        ),
        cta,
        vendors,
        id: 'collection',
    };
    if (collection.tabTitle && collection.productSummary) {
        tabs.push(collection);
    }

    return tabs;
};
