import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO/SEO';
import Button from '../components/UI/Button/Button';
import CocktailItem from '../components/CocktailItem/CocktailItem';
import * as cocktailData from '../utils/getCocktailData';
import SplitWidthFeaturedContent from '../components/SplitWidthFeaturedContent/SplitWidthFeaturedContent';
import TrendingCocktails from '../components/TrendingCocktails/TrendingCocktails';
import DoubleAssetImageParallaxing from '../components/DoubleAssetImageParallaxing/DoubleAssetImageParallaxing';
import { getDataFromSplitContentFeatured } from '../utils/drupal-fields-map';
//import { BartenderContextProvider } from '../components/Bartender/context/BartenderContext';
import { getSafe } from '../utils/getSafe';
import '../styles/detail-item.scss';
import { getImageFile } from '../utils/getAssetData';

export default function CocktailDetail({ data, pageContext }) {
    React.useEffect(() => {
        document.body.classList.add('colored-nav');

        return () => document.body.classList.remove('colored-nav');
    }, []);

    const langKey = pageContext.langcode;
    const name = getSafe(() => data.nodeCocktail.field_cocktail_name, '');
    const nameTwo = getSafe(() => data.nodeCocktail.field_cocktail_name_2, '');
    const title = getSafe(() => data.nodeCocktail.field_seo_metatag.title, '');
    const description = getSafe(() => data.nodeCocktail.field_seo_metatag.description, '');
    const keywords = getSafe(() => data.nodeCocktail.field_seo_metatag.keywords, null);
    const regions = getSafe(() => data.regions.edges, []).reduce((regionsData, data) => {
        let countries = getSafe(() => data.node.relationships.field_region, []);
        return [...regionsData, ...countries];
    }, []);
    const moreCocktailsButton = getSafe(
        () => data.nodeSiteSettings.field_label_more_coctkails_butto,
        'MORE COCKTAIL IDEAS'
    );
    const metaImage = getSafe(
        () =>
            data.nodeCocktail.relationships.field_cocktail_seo_meta_image.relationships.field_media_image.localFile
                .publicURL,
        null
    );
    const image = getImageFile(data.nodeCocktail, 'field_cocktail_image');
    const relatedCocktails = getDataFromSplitContentFeatured(
        getSafe(() => data.nodeCocktail.relationships.field_related_cocktails.relationships.columns, [])
    );
    const trendingCocktails =
        data.nodeCocktail.relationships.field_product_related &&
        data.nodeCocktail.relationships.field_product_related.relationships.field_trending_cocktails_section &&
        data.nodeCocktail.relationships.field_product_related.relationships.field_trending_cocktails_section.relationships.field_cocktail.map(
            item => {
                return {
                    cocktailImage: getImageFile(item.relationships.field_cocktail_item, 'field_cocktail_image'),
                    cocktailName: getSafe(() => item.relationships.field_cocktail_item.field_cocktail_name, ''),
                    cocktailNameTwo: getSafe(() => item.relationships.field_cocktail_item.field_cocktail_name_2, ''),
                    cocktailSlug: getSafe(() => item.relationships.field_cocktail_item.fields.slug, '/'),
                };
            }
        );
    const block = getSafe(() => data.nodeCocktail.relationships.field_double_asset_image_paralla, null);
    const cta = {
        title: getSafe(() => block.relationships.field_double_assets_imgparal_cta.field_title, ''),
        link: getSafe(
            () => block.relationships.field_double_assets_imgparal_cta.relationships.field_cta_url.path.alias,
            ''
        ),
        isLight: getSafe(() => block.relationships.field_double_assets_imgparal_cta.field_islight, false),
    };
    const trendingCocktailsTitle = getSafe(
        () =>
            data.nodeCocktail.relationships.field_product_related.relationships.field_trending_cocktails_section
                .field_trending_cocktails_title,
        ''
    );
    const background1 = getSafe(
        () =>
            data.nodeCocktail.relationships.field_product_related.relationships.field_trending_cocktails_section
                .field_background_color,
        '#ffffff'
    );
    const background2 = getSafe(
        () =>
            data.nodeCocktail.relationships.field_product_related.relationships.field_trending_cocktails_section
                .field_background_color_2,
        '#ffffff'
    );
    const virtualBartender = getSafe(
        () =>
            data.nodeCocktail.relationships.field_product_related.relationships.field_trending_cocktails_section
                .field_feature_virtual_bartender,
        false
    );
    let bartenderProps = {};
    if (virtualBartender) {
        bartenderProps = {
            cta: {
                title: getSafe(
                    () =>
                        data.nodeCocktail.relationships.field_product_related.relationships
                            .field_trending_cocktails_section.relationships.field_cta_vbartender.field_title,
                    ''
                ),
                isLight: getSafe(
                    () =>
                        data.nodeCocktail.relationships.field_product_related.relationships
                            .field_trending_cocktails_section.relationships.field_cta_vbartender.field_islight,
                    ''
                ),
            },
            titleImage: getSafe(
                () =>
                    data.nodeCocktail.relationships.field_product_related.relationships.field_trending_cocktails_section
                        .relationships.field_title_image.relationships.field_media_image.localFile
            ),
            bartenderIcon: getSafe(
                () =>
                    data.nodeCocktail.relationships.field_product_related.relationships.field_trending_cocktails_section
                        .relationships.field_bartender_icon_tc.relationships.field_media_image.localFile
            ),
            contentCopy: getSafe(
                () =>
                    data.nodeCocktail.relationships.field_product_related.relationships.field_trending_cocktails_section
                        .field_description_vbartender.value
            ),
        };
    }
    const newsLetterConfig = getSafe(() => data.nodeNewsletterForm, []);

    let allNewsletterFormSignUpCopy = getSafe(() => data.allNewsletterFormSignUpCopy, []);
    if(allNewsletterFormSignUpCopy.edges) {
        allNewsletterFormSignUpCopy = allNewsletterFormSignUpCopy.edges.map(function(elem) {
            return {
                copy:  getSafe(() => elem.node.field_signing_up_copy_2.value, ''),
                copy_2: getSafe(() => elem.node.field_signing_up_copy_block_2.value, ''),
                lang: elem.node.relationships.field_language.field_language_code,
                display_sign_up_checkbox: getSafe(() => elem.node.field_display_sign_up_checkbox, false),
            } 
        });
    }

    const characteristics = cocktailData.getCocktailCharacteristics(data);
    const vendors = cocktailData.getVendors(data);
    const tabs = cocktailData.getTabs(data, characteristics, vendors);
    return (
        // <BartenderContextProvider>
        <Layout
            langKey={langKey}
            shopMenu={data.nodeShop}
            dataMenu={getSafe(() => data.allMenuLinkContentMenuLinkContent, {})}
            siteSettings={getSafe(() => data.nodeSiteSettings, {})}
            pageContext={pageContext}
            langPageRelation={getSafe(() => data.relatedNodeCocktail.edges, [])}
            newsLetterConfig={newsLetterConfig}
            allNewsletterFormSignUpCopy={allNewsletterFormSignUpCopy}
            regions={regions}
            disableTheAgeGate={getSafe(() => data.nodeCocktail.field_disable_the_age_gate, false)}
        >
            <SEO
                title={title}
                lang={langKey}
                description={description}
                keywords={keywords}
                image={metaImage}
                langPageRelation={getSafe(() => data.relatedNodeCocktail.edges, [])}
                noIndex={getSafe(() => data.nodeCocktail.field_add_no_index_rule, false)}
            />
            <CocktailItem name={name} nameTwo={nameTwo} image={image} tabs={tabs} />
            <div className="blv-more-cta-wrapper">
                <Button
                    className="blv-more-cta"
                    isLight={true}
                    title={moreCocktailsButton}
                    link={`/${langKey}/vodka-cocktails`}
                />
            </div>
            {block && (
                <DoubleAssetImageParallaxing
                    copyAlignment={getSafe(() => block.field_copy_alignment, 'left')}
                    titleCopy={getSafe(() => block.field_title, '')}
                    contentCopy={getSafe(() => block.field_content_copy.value, '')}
                    eyebrowCopy={getSafe(() => block.field_eyebrowcopy, '')}
                    imageSrc1={getSafe(
                        () => block.relationships.field_media_image_1.relationships.field_media_image.localFile,
                        {}
                    )}
                    imageSrc1Alt={getSafe(() => block.relationships.field_media_image_1.field_media_image.alt, '')}
                    imageSrc2={getSafe(
                        () => block.relationships.field_media_image_2.relationships.field_media_image.localFile,
                        {}
                    )}
                    imageSrc2Alt={getSafe(() => block.relationships.field_media_image_2.field_media_image.alt, '')}
                    backgroundColor={getSafe(() => block.field_background_color, '#e5d8cc')}
                    secondBackgroundColor={getSafe(() => block.field_background_color_2, '#e5d8cc')}
                    textToggle={getSafe(() => block.field_toggle_content_copy, false)}
                    cta={cta}
                />
            )}
            {trendingCocktails && (
                <TrendingCocktails
                    cocktails={trendingCocktails}
                    title={trendingCocktailsTitle}
                    backgroundColor={background1}
                    secondBackgroundColor={background2}
                    virtualBartender={virtualBartender}
                    bartenderProps={bartenderProps}
                />
            )}
            {relatedCocktails && <SplitWidthFeaturedContent columns={relatedCocktails} />}
        </Layout>
        // </BartenderContextProvider>
    );
}

export const pageQuery = graphql`
    query($slug: String!, $langcode: String!, $pagecode: String!) {
        nodeSiteSettings(relationships: { field_language: { field_language_code: { eq: $langcode } } }) {
            ...SiteSettingsSomeItems
        }
        nodeNewsletterForm(relationships: { field_language: { field_language_code: { eq: $langcode } } }) {
            ...NewsletterItems
        }
        allNewsletterFormSignUpCopy: allNodeNewsletterForm {
            edges {
                node {
                    field_signing_up_copy_2 {
                        value
                    }
                    field_signing_up_copy_block_2 {
                        value
                    }
                    relationships {
                        field_language {
                            field_language_code
                        }
                    }
                    field_display_sign_up_checkbox
                }
            }
        }
        regions: allNodeRegionsConfigurations(filter: { title: { regex: "/Regions Configurations*/" } }) {
            edges {
                node {
                    relationships {
                        field_region {
                            value: field_code
                            label: field_name
                            relationships {
                                field_local {
                                    name
                                    field_language_code
                                }
                            }
                            field_legal_for_en_int {
                                value
                            }
                            field_legal_for_de_de {
                                value
                            }
                            field_legal_for_fr_fr {
                                value
                            }
                            field_legal_bottom_for_de_de {
                                value
                            }
                            field_legal_bottom_for_en_int {
                                value
                            }
                            field_legal_bottom_for_fr_fr {
                                value
                            }
                            field_display_sign_up_checkbox
                        }
                    }
                }
            }
        }
        nodeShop(relationships: { field_language: { field_language_code: { eq: $langcode } } }) {
            ...ShopItems
        }
        nodeCocktail(
            fields: { slug: { eq: $slug } }
            relationships: { field_language: { field_language_code: { eq: $langcode } } }
        ) {
            ...CocktailItems
        }

        relatedNodeCocktail: allNodeCocktail(
            filter: {
                field_page_code: { eq: $pagecode }
                relationships: { field_language: { field_language_code: { ne: $langcode } } }
            }
        ) {
            edges {
                node {
                    fields {
                        slug
                    }
                    path {
                        alias
                    }
                    relationships {
                        field_language {
                            field_language_code
                        }
                    }
                }
            }
        }
        allMenuLinkContentMenuLinkContent(
            filter: {
                relationships: { field_language_code: { field_language_code: { eq: $langcode } } }
                enabled: { eq: true }
            }
            sort: { fields: weight, order: ASC }
        ) {
            edges {
                node {
                    weight
                    name: title
                    drupal_id
                    link {
                        uri
                    }
                    drupal_parent_menu_item
                    langcode
                    relationships {
                        field_content {
                            ...LegalMenuItems
                            ...ParagraphMenuItems
                            ...ContactMenuItems
                            ...NutritionMenuItems
                            ...ProductsMenuItems
                        }
                        field_collection_related {
                            ...CollectionMenuItems
                        }
                    }
                    menu_name
                    enabled
                    bundle
                }
            }
        }
    }
`;

CocktailDetail.propTypes = {
    data: PropTypes.object,
    pageContext: PropTypes.object,
};
