import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { TabContainer } from '../TabContainer/TabContainer';
import './CocktailItem.scss';

const CocktailItem = ({ name, nameTwo = '', tabs, image }) => {
    const { isMobileOrTablet, isMobile } = useBreakpoint();

    return (
        <section className="section blv-cocktail-item blv-detail-item">
            <div className="container">
                <div className={`columns is-gapless is-multiline ${isMobileOrTablet ? 'is-centered' : ''}`}>
                    <div className="column is-8-tablet is-5-desktop has-text-centered">
                        {isMobileOrTablet && (
                            <>
                                <h1 className="blv-detail-title mobile">{name.toLowerCase()}</h1>
                                <span className="blv-eyebrow with-border">{nameTwo}</span>
                            </>
                        )}
                        {image && (
                            <GatsbyImage
                                image={image.src.childImageSharp.gatsbyImageData}
                                alt={image.alt}
                                title={`${name} cocktail`}
                            />
                        )}
                    </div>
                    <div
                        className="column is-12-tablet is-7-desktop is-6-widescreen is-5-fullhd"
                        style={{ paddingBottom: isMobile && `20px` }}
                    >
                        {!isMobileOrTablet && (
                            <>
                                <h1 className="blv-detail-title">{name.toLowerCase()}</h1>
                                <span className="blv-eyebrow with-border">{nameTwo}</span>
                            </>
                        )}
                        {tabs && <TabContainer className="blv-tabs-cocktail" tabs={tabs} />}
                    </div>
                </div>
            </div>
        </section>
    );
};

CocktailItem.propTypes = {
    name: PropTypes.string.isRequired,
    nameTwo: PropTypes.string,
    image: PropTypes.object.isRequired,
    tabs: PropTypes.array.isRequired,
};

export default CocktailItem;
